@font-face {
  font-family: 'e-Ukraine';
  src: url('../font/e-Ukraine-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-Ukraine-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-Ukraine-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-Ukraine-Bold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-Ukraine-Bold.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../font/e-Ukraine-Bold.otf')
      format('opentype');
  /* OpenType */
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../font/e-Ukraine-Light.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-Ukraine-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-Ukraine-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-Ukraine-Light.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-Ukraine-Light.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../font/e-Ukraine-Light.otf')
      format('opentype');
  /* OpenType */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../font/e-Ukraine-Medium.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-Ukraine-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-Ukraine-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-Ukraine-Medium.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-Ukraine-Medium.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../font/e-Ukraine-Medium.otf')
      format('opentype');
  /* OpenType */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../font/e-Ukraine-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-Ukraine-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-Ukraine-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-Ukraine-Regular.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-Ukraine-Regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../font/e-Ukraine-Regular.otf')
      format('opentype');
  /* OpenType */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'e-Ukraine';
  src: url('../font/e-Ukraine-Thin.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-Ukraine-Thin.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-Ukraine-Thin.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-Ukraine-Thin.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-Ukraine-Thin.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('../font/e-Ukraine-Thin.otf')
      format('opentype');
  /* OpenType */
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'e-UkraineHead';
  src: url('../font/e-UkraineHead-Bold.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-UkraineHead-Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-UkraineHead-Bold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-UkraineHead-Bold.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-UkraineHead-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'e-UkraineHead';
  src: url('../font/e-UkraineHead-Light.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-UkraineHead-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-UkraineHead-Light.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-UkraineHead-Light.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-UkraineHead-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'e-UkraineHead';
  src: url('../font/e-UkraineHead-Medium.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-UkraineHead-Medium.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-UkraineHead-Medium.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-UkraineHead-Medium.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-UkraineHead-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'e-UkraineHead';
  src: url('../font/e-UkraineHead-Regular.eot');
  /* IE9 Compat Modes */
  src: url('../font/e-UkraineHead-Regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../font/e-UkraineHead-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../font/e-UkraineHead-Regular.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../font/e-UkraineHead-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Oks-Free';
  src: url('../font/Oks-Free.otf');
  font-weight: normal;
  font-style: normal;
}
html {
  background-color: #faf9f2;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

* {
  font-family: 'e-Ukraine';
}

main::-webkit-scrollbar {
  display: none !important;
}

/* Скрываем scrollbar для IE, Edge и Firefox */
main {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
}

::-webkit-scrollbar {
  display: none !important;
}

h2 {
  font-size: 20px;
}

p {
  margin: 0;
  padding: 0;

  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
  text-align: inherit;
}

.ant-tooltip-inner {
  padding: 20px !important;
  font-size: 13px !important;
  color: #282119 !important;

  @media screen and (max-width: 1160px) {
    font-size: 12px !important;
  }
}

#tidio-chat-iframe {
  @media screen and (max-width: 1160px) {
    max-height: 90% !important;

    transform: translateY(-38px) !important;
  }
}
